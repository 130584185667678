.Navbar {
  display: flex;
  justify-content: space-around;
  align-items: left;
  padding: 10px;
  background-color: #f7f7f7; /* Light grey background for navbar */
}

.Navbar a {
  color: #333; /* Dark text color against light background */
  text-decoration: none;
  margin: 0 10px;
}

.Navbar a:hover {
  text-decoration: underline;
}
